$positions: "absolute" "relative" "fixed" "revert" "sticky";

@each $i in $positions{
  .position-#{$i}{
    position: #{$i};
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
