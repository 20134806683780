$sizeRange: 40;
$sizeBase: 16px;

@for $i from 1 through $sizeRange {
  $number: $i * $sizeBase;
  .height-#{$number} {
    height: ($number);
  }

  .width-#{$number} {
    width: ($number);
    min-width: ($number);
  }

  .line-height-#{$number} {
    line-height: ($number);
  }

  .margin-#{$number} {
    margin: $number;
  }

  .padding-#{$number} {
    padding: $number;
  }

  .margin-left-#{$number} {
    margin-left: ($number);
  }

  .margin-right-#{$number} {
    margin-right: ($number);
  }

  .margin-top-#{$number} {
    margin-top: ($number);
  }

  .margin-bottom-#{$number} {
    margin-bottom: ($number);
  }

  .padding-bottom-#{$number} {
    padding-bottom: ($number);
  }

  .padding-top-#{$number} {
    padding-top: ($number);
  }

  .padding-left-#{$number} {
    padding-left: ($number);
  }

  .padding-right-#{$number} {
    padding-right: ($number);
  }

  .padding-bottom-#{$number} {
    padding-bottom: ($number);
  }

  .padding-top-#{$number} {
    padding-top: ($number);
  }
}

.height-100vh {
  height: 100vh;
}

.height-100p {
  height: 100%;
}

.width-100p {
  width: 100%;
}

.width-100px {
  width: 100px;
  min-width: 100px
}

.width-300px {
  width: 300px;
  min-width: 300px
}

.height-150px{
  height: 150px;
}

.margin-top-40px {
  margin-top: 40px;
}

.margin-bottom-40px {
  margin-bottom: 40px;
}


$borderSizes: 20;
$borderRange: 2px;

@for $i from 1 through $borderSizes {
  $number: $i * $borderRange;

  .border-radius-#{$number} {
    border-radius: $number;
  }
}
.min-height-100vh {
  min-height: 100vh;
}

.font-size-20px {
  font-size: 20px;
}

.font-size-32px {
  font-size: 32px;
}

.font-size-36px {
  font-size: 36px;
}

