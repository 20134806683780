@import 'antd/dist/antd.css';
@import './themes/sizes.scss';
@import './themes/positions.scss';
@import './themes/display.scss';

body {
  background-color: #f0f2f5;
}

#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: url("./assets/logo.png") 100% 100%;
}

.site-layout-background {
  background: #fff;
}