*::-webkit-scrollbar {
  width: 0;
  display: none;
}


.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}
.flex-wrap{
  flex-wrap: wrap;
}

.justify-between {
  justify-content: space-between;
}

$i: 0;

@while $i <=10 {
  .flex-#{$i} {
    flex: #{$i};
  }

  $i: $i + 1;
}

.background-color-ffffff {
  background-color: #ffffff;
}

.background-color-f0f0f0 {
  background-color: #f0f0f0;
}

.background-color-f0f2f5 {
  background-color: #f0f2f5;
}

.background-color-d9d9d9 {
  background-color: #d9d9d9;
}

.background-color-1890ff {
  background-color: #1890ff;
}

.font-color-ffffff {
  color: #ffffff;
}

.background-color-f9f9f9 {
  background-color: #f9f9f9;
}

.background-color-f6f6f6 {
  background-color: #f6f6f6;
}

.background-color-f4f7fc {
  background-color: #f4f7fc;
}

.box-shadow-1 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.font-color-1e90ff {
  color: #1e90ff;
}

.font-color-707070 {
  color: #707070;
}

.font-color-7e84a3 {
  color: #7E84A3;
}

.font-color-bce0fd {
  color: #bce0fd;
}

.font-color-707070 {
  color: #707070;
}

.font-weight-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.ovf-scroll {
  overflow: scroll;
}

.hover-bold:hover {
  font-weight: bold;
}

.hover-underline:hover {
  text-decoration: underline;
}

.obj-fit-cover {
  object-fit: cover;
}

.hover-zoom {
  transition-duration: 0.25s;
}

.hover-zoom:hover {
  transition-duration: 0.25s;
  transform: scale(1.5);
}

.float-right {
  float: right;
}